<template>
  <b-form>
    <b-card>
      <b-card-text>
        <b-row>
          <b-col
            sm="12"
            md="6"
          ><h6 class="mb-1">
            Oran Güncelle
          </h6></b-col>
        </b-row>
        <b-form-group label="Yıl">
          <cleave
            id="year"
            v-model="formData.year"
            class="form-control"
            :raw="true"
            :options="options.year"
            placeholder="Yıl"
          />
        </b-form-group>
        <b-form-group label="Ay">
          <cleave
            id="month"
            v-model="formData.month"
            class="form-control"
            :raw="true"
            :options="options.month"
            placeholder="Ay"
          />
        </b-form-group>
        <b-form-group label="Oran">
          <cleave
            id="value"
            v-model="formData.value"
            class="form-control"
            :raw="true"
            :options="options.price"
            placeholder="Oran"
          />
        </b-form-group>
      </b-card-text>
    </b-card>
    <b-button
      variant="danger"
      :disabled="submitStatus"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </b-form>
</template>

<script>
import {
  BForm,
  BCard,
  BCardText,
  BFormGroup,
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'

export default {
  name: 'RatesEdit',
  components: {
    BForm,
    BCard,
    BCardText,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    Cleave,
  },
  data() {
    return {
      submitStatus: false,
      options: {
        price: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalMark: ',',
          delimiter: '.',
        },
        year: {
          blocks: [4],
          numericOnly: true,
        },
        month: {
          blocks: [2],
          numericOnly: true,
        },
      },
    }
  },
  computed: {
    formData() {
      return this.$store.getters['interestRate/getRate']
    },
    saveStatus() {
      return this.$store.getters['interestRate/getRateSaveStatus']
    },
  },
  watch: {
    brandData(val) {
      this.setFormData(val)
    },
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: 'Kayıt işleminiz başarılı!',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: 'Kayıt işleminiz yapılamadı.! Lütfen tekrar deneyiniz.',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
  },
  created() {
    this.getRate()
  },
  methods: {
    getRate() {
      this.$store.dispatch('interestRate/rateView', this.$route.params.id)
    },
    setFormData(data) {
      this.formData = data
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    submitForm() {
      this.submitStatus = true
      this.$store.dispatch('interestRate/rateSave', this.formData)
    },
  },
}
</script>
